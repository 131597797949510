import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Table,
  Spinner,
  Button,
  Modal,
  Form,
  FormControl,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import {
  AiOutlineRedo,
  AiOutlineEdit,
  AiOutlineSave,
  AiOutlineClose,
  AiOutlineClockCircle,
} from "react-icons/ai";
import riskMappings from "../risk-mappings.json";
import HistoryAudit from "../HistoryAudit";
import ReactPaginate from "react-paginate";

const defaultOptions = [
  "firm",
  "trader",
  "maxdollaramount",
  "totalallowamount",
  "totalallowqty",
  "DupOrd",
  "SpeedChk",
  "active_account",
  "percentagelimit",
  "washcheck",
];

class RiskSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      risk_setting: this.props.risk_setting,
      showConfigureDisplayModal: false,
      displayOptions: this.props.displayOptions,
      maxAmountOfOptions: 100,
      entryToEdit: {
        item: "",
        option: "",
        value: "",
      },
      traderQuery: "",
      firmQuery: "",
      noMatchFound: "",
      currentPage: 0,
      limit: 20,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      this.setState({
        risk_setting: nextProps.risk_setting,
        displayOptions: nextProps.displayOptions,
      });
    }
  }

  handleCloseConfigureDisplayModal = () => {
    this.setState({
      showConfigureDisplayModal: false,
    });
  };

  handleShowConfigureDisplayModal = () => {
    this.setState({
      showConfigureDisplayModal: true,
    });
  };

  parseKeysIntoArray = (object) => {
    let array = [];
    if (object) {
      if (Object.keys(object).length > 0) {
        array = Object.keys(object);
      }
    }

    return array;
  };

  handleModifyDisplayOptions = (key) => {
    const newOptions = JSON.parse(JSON.stringify(this.state.displayOptions));

    if (newOptions.includes(key)) {
      const index = newOptions.indexOf(key);
      newOptions.splice(index, 1);
    } else {
      if (newOptions.length < this.state.maxAmountOfOptions) {
        newOptions.push(key);
      }
    }

    this.props.setDisplayOptionsRiskSettings(newOptions);
  };

  handleResetDisplayOptions = () => {
    this.setState({ displayOptions: defaultOptions });
  };

  handleSelectEntryToEdit = (option, item, value) => {
    this.setState({
      entryToEdit: {
        option: option,
        item: item,
        value: value,
      },
    });
  };

  handleStopEditingEntry = () => {
    this.setState({
      entryToEdit: {
        option: "",
        item: "",
        value: "",
      },
    });
  };

  handleEditEntry = (e, item, option) => {
    this.setState({
      entryToEdit: {
        ...this.state.entryToEdit,
        value: e.target.value,
      },
    });
  };

  handleSaveEditedEntry = () => {
    const { option, value, item } = this.state.entryToEdit;
    const new_risk_setting = this.state.risk_setting;

    const message = {
      type: "account_update",
      firm: item.firm,
      trader: item.trader,
    };

    message[option] = value;

    this.props.websocket.send(JSON.stringify(message));
    this.handleStopEditingEntry();
  };

  handleFilterData = () => {
    const { risk_setting, traderQuery, firmQuery } = this.state;
    let content = risk_setting;
    if (risk_setting) {
      if (traderQuery) {
        const userIds = Object.keys(content);
        let obj = {};
        const filter = userIds.filter(function (userId) {
          function isValid(str) {
            // tests validity of the string, which may throw an error in regex
            return !/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g.test(str);
          }
          const query = isValid(traderQuery) ? traderQuery : `\\` + traderQuery;
          const regexp = new RegExp(query, "i");
          if (userId.match(regexp)) {
            obj[userId] = risk_setting[userId];
          }
        });
        content = obj;
      }

      if (firmQuery) {
        let obj = {};
        const test = Object.keys(content).map(function (key, index) {
          function isValid(str) {
            // tests validity of the string, which may throw an error in regex
            return !/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g.test(str);
          }

          const query = isValid(firmQuery) ? firmQuery : `\\` + firmQuery;
          const regexp = new RegExp(query, "i");
          if (content[key].firm.match(regexp)) {
            obj[key] = content[key];
          }
        });

        content = obj;
      }
    }

    return content;
  };

  handleChangeQuery = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleShowHistoryAuditModal = (data) => {
    this.setState({
      showHistoryAuditModal: true,
      historyAuditData: data,
    });
  };

  handleHideHistoryAuditModal = () => {
    this.setState({
      showHistoryAuditModal: false,
    });
  };

  handleClickPage = (number) => {
    const scrollHere = document.getElementById("scroll-here");
    const topPos = scrollHere.offsetTop;
    const test = document.getElementsByClassName("admin-content-pane");
    test[0].scrollTop = topPos;
    this.setState({
      currentPage: number.selected,
    });
  };

  /****************************************************************************/

  renderLoading = () => {
    const span = this.state.displayOptions.length;
    return (
      <tr>
        <td colSpan={span}>
          <Row noGutters className="app-spinner full-height">
            <Spinner animation="border" className="common-grey-spinner" />
          </Row>
        </td>
      </tr>
    );
  };

  renderTable = () => {
    const { displayOptions } = this.state;
    const risk_setting = this.handleFilterData();
    const arrayOfKeys = this.parseKeysIntoArray(risk_setting);

    let body;
    if (arrayOfKeys.length > 0) {
      const { currentPage, limit } = this.state;
      const a = currentPage == 0 ? 0 : (currentPage + 1) * limit - limit;
      const b = (currentPage + 1) * limit;
      let arrayOfValues = Object.values(risk_setting);
      arrayOfValues = arrayOfValues.slice(a, b);
      body = arrayOfValues.map((item, index) =>
        this.renderTableItem(item, index)
      );
    } else {
      const span =
        this.state.displayOptions && this.state.displayOptions.length;
      body = (
        <tr>
          <td colSpan={span} className="centered-td">
            Nothing found
          </td>
        </tr>
      );
    }

    let tableHeaders;
    if (displayOptions.length > 0) {
      tableHeaders = displayOptions.map((option, index) =>
        this.renderTableHeader(option, index)
      );
    }

    return (
      <div className="scrollable-wrapper">
        <Table
          responsive
          bordered
          className="submissions-table risk-control-table"
        >
          <thead className="table-header">
            <tr>{tableHeaders}</tr>
          </thead>

          <tbody className="table-body">{body}</tbody>
        </Table>
      </div>
    );
  };

  renderTableHeader = (option, index) => {
    const riskSettingOption = riskMappings[option]
      ? riskMappings[option]
      : option;
    return (
      <th key={"tableHeader - index: " + index} className="table-header-cell">
        {riskSettingOption}
      </th>
    );
  };

  renderTableItem = (item, index) => {
    const { displayOptions } = this.state;

    let tableContents;
    if (displayOptions.length > 0) {
      tableContents = displayOptions.map((option, index) =>
        this.renderMatchingTableItem(item, option, index)
      );
    }

    const tooltip = (
      <Tooltip id="button-tooltip">
        Audit changes to risk settings for this user
      </Tooltip>
    );
    const auditChangesButton = (
      <OverlayTrigger placement="left" delay={{ show: 250 }} overlay={tooltip}>
        <AiOutlineClockCircle
          onClick={() => this.handleShowHistoryAuditModal(item)}
          className="form-icon audit-changes-icon"
        />
      </OverlayTrigger>
    );
    return (
      <tr key={"tableItem - index: " + index} className="table-cell">
        {tableContents}
        {auditChangesButton}
      </tr>
    );
  };

  renderMatchingTableItem = (item, option, index) => {
    const { entryToEdit } = this.state;

    let content;
    if (option == "trader" || option == "firm") {
      content = item[option];
    } else {
      if (
        entryToEdit.item.trader === item.trader &&
        entryToEdit.option === option
      ) {
        content = (
          <Row noGutters className="table-cell-row-editing">
            <FormControl
              onChange={(e) => this.handleEditEntry(e, item, option)}
              value={entryToEdit.value}
              className="table-cell-formControl"
            />

            <div>
              <AiOutlineClose
                onClick={this.handleStopEditingEntry}
                className="individual-form-icon"
              />
              <AiOutlineSave
                onClick={() => this.handleSaveEditedEntry()}
                className="individual-form-icon"
              />
            </div>
          </Row>
        );
      } else {
        content = (
          <Row noGutters className="table-cell-row">
            {item[option]}
            <AiOutlineEdit
              className="individual-form-icon"
              onClick={() =>
                this.handleSelectEntryToEdit(option, item, item[option])
              }
            />
          </Row>
        );
      }
    }

    return (
      <td
        key={"matchingTableItem - index: " + index}
        className="matching-table-item"
      >
        {content}
      </td>
    );
  };

  renderConfigureDisplayButton = () => {
    return (
      <Button
        onClick={this.handleShowConfigureDisplayModal}
        className="teal-button"
        size="sm"
      >
        Configure Display
      </Button>
    );
  };

  renderConfigureDisplayModal = () => {
    const {
      showConfigureDisplayModal,
      risk_setting,
      maxAmountOfOptions,
    } = this.state;
    const arrayOfKeys = this.parseKeysIntoArray(risk_setting);

    let content;
    if (arrayOfKeys.length > 0) {
      const values = risk_setting[arrayOfKeys[0]];
      const arrayOfKeysFromValues = Object.keys(values);
      content = arrayOfKeysFromValues.map((key) => this.renderKeyCheckbox(key));
    }

    return (
      <Modal
        centered
        className="common-modal"
        show={showConfigureDisplayModal}
        onHide={this.handleCloseConfigureDisplayModal}
      >
        <Modal.Header className="common-modal-header" closeButton>
          Risk Settings Display Options
        </Modal.Header>

        <Modal.Body>
          <Form>
            <Row noGutters className="risk-settings-content-row">
              {content}
            </Row>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <AiOutlineRedo
            onClick={this.handleResetDisplayOptions}
            className="individual-form-icon"
          />
          <Button
            onClick={this.handleCloseConfigureDisplayModal}
            className="teal-button"
            size="sm"
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  renderKeyCheckbox = (key) => {
    const { displayOptions } = this.state;

    const label = riskMappings[key] ? riskMappings[key] : key;
    return (
      <Form.Check
        checked={displayOptions.includes(key) ? true : false}
        onChange={() => this.handleModifyDisplayOptions(key)}
        className="risk-settings-key-checkbox"
        key={key}
        name={key}
        size="sm"
        label={label}
      />
    );
  };

  renderSearchBar = () => {
    const { traderQuery, firmQuery } = this.state;
    const disabled = !traderQuery ? true : false;

    return (
      <Row noGutters className="search-bar-row">
        <div className="search-bar">
          <label className="search-label">Look up a trader:</label>
          <FormControl
            value={traderQuery}
            name="traderQuery"
            placeholder="Look up a trader..."
            className="search-form-control"
            onChange={this.handleChangeQuery}
          />
        </div>
        <div className="search-bar">
          <label className="search-label">Look up a firm:</label>
          <FormControl
            value={firmQuery}
            name="firmQuery"
            placeholder="Look up a firm..."
            className="search-form-control"
            onChange={this.handleChangeQuery}
          />
        </div>
      </Row>
    );
  };

  renderHistoryAuditModal = () => {
    const { showHistoryAuditModal, historyAuditData } = this.state;

    return (
      <Modal
        centered
        className="common-modal"
        show={showHistoryAuditModal}
        onHide={this.handleHideHistoryAuditModal}
      >
        <Modal.Header className="common-modal-header" closeButton>
          Audit Changes to Risk Settings
        </Modal.Header>

        <HistoryAudit historyAuditData={historyAuditData} />
        <Modal.Footer>
          <Button className="teal-button" size="sm">
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  renderPagination = () => {
    const keys = Object.keys(this.state.risk_setting);

    if (keys.length > 0) {
      const total = keys.length;
      const { currentPage, limit } = this.state;
      const numberOfPages = Math.ceil(total / limit);

      return (
        <Row noGutters className="pagination-row">
          <ReactPaginate
            previousLabel={"<"}
            previousClassName={"pagination__item pagination__arrow"}
            previousLinkClassName={"pagination__icon pagination__icon--prev"}
            nextLabel={">"}
            nextClassName={"pagination__item pagination__arrow"}
            nextLinkClassName={"pagination__icon pagination__icon--next"}
            breakLabel={"..."}
            breakClassName={"pagination__ellipsis"}
            breakLinkClassName={"pagination__ellipsis-link"}
            pageCount={numberOfPages}
            marginPagesDisplayed={1}
            pageRangeDisplayed={3}
            onPageChange={this.handleClickPage}
            containerClassName={"pagination__list"}
            activeLinkClassName={"pagination__link--active"}
            pageClassName={"pagination__item"}
            pageLinkClassName={"pagination__link"}
            forcePage={currentPage}
          />
        </Row>
      );
    }
  };

  render() {
    return (
      <Container fluid className="admin-content-container" id="scroll-here">
        {this.renderConfigureDisplayModal()}
        {this.renderHistoryAuditModal()}

        <Row noGutters className="forms-header">
          <h4>Risk Control Settings</h4>
          {this.renderConfigureDisplayButton()}
        </Row>

        <div className="forms-table-box">
          {this.renderSearchBar()}
          {this.renderTable()}
          {this.renderPagination()}
        </div>
      </Container>
    );
  }
}

export default RiskSettings;
