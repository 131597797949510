import React, { PureComponent } from 'react'
import {
  Nav,
  Row,
  Col,
  Button,
  FormControl,
  Container,
  FormCheck,
  Card,
  Image,
  NavLink,
} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import logo from '../../images/FI_LOGO.png'

class Login extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      email: '',
      password: '',
      keepMeLoggedIn: true,
    }
  }

  handleOnChange = e => {
    const { name, value } = e.target
    this.setState({ [name]: value })
  }

  handleLogin = () => {
    const { email, password, keepMeLoggedIn } = this.state
    const body = { email, password, keepMeLoggedIn }

    this.props.loginRequest(body)
  }

  handleKeepMeLoggedIn = () => {
    this.setState({
      keepMeLoggedIn: !this.state.keepMeLoggedIn
    })
  }

  renderLogo = () => (
    <div className='login-logo-row'>
      <div className='login-logo-box'>
        <img src={logo} className='login-logo' />
      </div>
    </div>
  )

  render() {
    const { email, password } = this.state
    const disabled = (email && password) ? false : true

    return (
      <Container fluid className='login-container'>
        <Row noGutters className='login-row'>
          <Col sm={8} md={6} lg={4} xl={4} className='login-col'>
            <Card className='login-box'>
              <Row noGutters>
                <Col className='login-content-col'>

                  <Row noGutters className='login-header'>
                    <Link to={'/'}>
                      <Image fluid src={logo} className='login-logo' />
                    </Link>
                  </Row>

                  <Row noGutters className='login-box-padding login-formcontrol-row'>
                    <Col className='login-box-col'>
                      <Row noGutters className='login-welcome'>
                        <Col>
                          <h1>Welcome</h1>
                          <p>Log in to continue</p>
                        </Col>
                      </Row>

                      <Row noGutters className='login-formcontrol-container'>
                        <div className='login-formcontrol-box'>
                          <div className='login-formcontrol-text'>
                            Email
                          </div>
                          <FormControl
                            size='sm'
                            name='email'
                            value={email}
                            onChange={this.handleOnChange}
                            className='login-formcontrol' />
                        </div>
                      </Row>

                      <Row noGutters className='login-formcontrol-container'>
                        <div className='login-formcontrol-box'>
                          <div className='login-formcontrol-text'>
                            Password
                          </div>
                          <FormControl
                            size='sm'
                            name='password'
                            type='password'
                            value={password}
                            onChange={this.handleOnChange}
                            className='login-formcontrol' />
                        </div>
                      </Row>

                      <Row noGutters className='login-button-row'>
                        <Button
                          size='sm'
                          disabled={disabled}
                          onClick={this.handleLogin}
                          className='teal-button'>
                          LOGIN
                        </Button>
                      </Row>

                      <div className='login-navlinks'>
                        <NavLink to='/forgot'>Forgot Password</NavLink>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default Login
