import { connect } from "react-redux";
import Admin from "./Admin";
import RiskSettings from "./RiskSettings";
import CurrentRisk from "./CurrentRisk";
import HistoryAudit from "./HistoryAudit";
import RiskAlerts from "./RiskAlerts";
import CaseManagement from "./CaseManagement";
import PreTradeRisk from "./PreTradeRisk";
import PatternDetectionAlerts from "./PatternDetectionAlerts";
import MarketDataReplay from "./MarketDataReplay";
import OrderTradeQuery from "./OrderTradeQuery";
import { logout } from "../../redux/actions/login";
import { getNavbarLinksRequest } from "../../redux/actions/admin";

const mapStateToProps = (state) => ({
  risk_setting: state.admin.risk_setting,
  account: state.login.userData,
  navbarLinks: state.admin.navbarLinks,
});

const mapDispatchToProps = {
  logout,
  getNavbarLinksRequest,
};

export {
  RiskSettings,
  CurrentRisk,
  HistoryAudit,
  RiskAlerts,
  CaseManagement,
  PreTradeRisk,
  PatternDetectionAlerts,
  MarketDataReplay,
  OrderTradeQuery,
};

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
